import clsx from 'clsx'
import { useState } from 'react'

const baseStyles = "relative inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10"

type ButtonMultiSelectProps = ({className?: string, values: string[], selectedValue?: string, onSelectionChange: (newValue: string) => void})

export function ButtonMultiSelect({ className, values, selectedValue, onSelectionChange }: ButtonMultiSelectProps) {
    className = clsx(baseStyles, className)
    let [selectedIdx, setSelectedIdx] = useState(selectedValue ? values.indexOf(selectedValue) : 0)

    function selectionChange(idx: number) {
        setSelectedIdx(idx)
        onSelectionChange(values[idx])
    }

    return (
        <span className="isolate inline-flex rounded-md ">
            {values.map((value, idx: number) => {
                let internalClassName = null
                if(idx === 0 ){
                    internalClassName = clsx("rounded-l-md", className)
                } else if(idx === values.length - 1) {
                    internalClassName = clsx("rounded-r-md", className)
                } else {
                    internalClassName = className
                }

                if (selectedIdx === idx) {
                    internalClassName = clsx("bg-slate-100 cursor-default border-b-primary-500 border-b-4", internalClassName)
                } else {
                    internalClassName = clsx("shadow hover:bg-gray-50", internalClassName)
                }
                
                return <button
                    type="button"
                    key={value}
                    className={internalClassName}
                    onClick={() => selectionChange(idx)}
                >
                    {value}
                </button>
            })
            }
        </span>
    )
}