import { roll } from "./dice-util"
import { SKILLS } from "./skills-data"

export type CharacType = { STR: number; CON: number; SIZ: number; DEX: number; APP: number; INT: number; POW: number; EDU: number; }

export class Investigator {
    info: InvestigatorInfo = new InvestigatorInfo()
    id: String = ""
    characteristics: CharacType = {
        STR: 0,
        DEX: 0,
        INT: 0,
        CON: 0,
        APP: 0,
        POW: 0,
        SIZ: 0,
        EDU: 0,
    }
    stats = {
        health: 0,
        luck: 0,
        sanity: 0,
        magic: 0,
        BD: 0,
        MOV: 0,
    }
    skills = initSkills()
    initStep: number = 0
}

export type Skill = SkillItem | SpecializedSkill;

export interface SkillItem {
    name: string;
    value: number;
}

export interface SpecializedSkill {
    name: string;
    specializations: SkillItem[];
}

function initSkills() {
    return SKILLS.map(skill => {
        var obj: Skill = {
            name: skill.name,
            value: skill.default_percentage
        }
        return obj
    })
}

export class InvestigatorInfo {
    name: String = "New Investigator"
    age: number = 0
    occupation: String = ""
    nationality: String = ""
    archetype: String = "Adventurer"
    talents: String[] = []
}

export function calcBD(STR: number, SIZ: number): number {
    let sum = STR + SIZ
    if (sum <= 64) {
        return -2
    } else if (sum <= 84) {
        return -1
    } else if (sum <= 124) {
        return 0
    } else if (sum <= 164) {
        return roll(1,4)
    } else if (sum <= 204) {
        return roll(1,6)
    } else if (sum <= 284) {
        return roll(2,6)
    } else if (sum <= 364) {
        return roll(3,6)
    } else if (sum <= 444) {
        return roll(4,6)
    } else if (sum <= 524) {
        return roll(5,6)
    } else {
        return roll(6,6)
    }
}

export function calcMOV(DEX: number, STR: number, SIZ: number) {
    if (DEX < SIZ && STR < SIZ) {
        return 7
    } else if (DEX > SIZ && STR > SIZ) {
        return 9
    } else {
        return 8
    }
}

export function updateSkills(investigatorToUpdate: Investigator, newSkillValue: Skill[]) {
    newSkillValue.forEach( it => {
        var arrayToUpdate = investigatorToUpdate.skills
        if (it.name.indexOf("(") !== -1 && it.name.indexOf("(any)") === -1) {
            const specialization = it.name.split(" ")[0]
            const specializationIndex = investigatorToUpdate.skills.findIndex(skill => skill.name === specialization)
            if(specializationIndex !== -1) {
                arrayToUpdate = (investigatorToUpdate.skills[specializationIndex] as SpecializedSkill).specializations
            } else {
                arrayToUpdate.push(it)
            }
        } 
        const indexToUpdate = arrayToUpdate.findIndex(skill => skill.name === it.name)
        if(indexToUpdate === -1) {
            arrayToUpdate.push(it)
        } else {
            arrayToUpdate[indexToUpdate] = it
        }
    })
}