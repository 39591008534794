'use client'
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react'
import { useState } from 'react'

const statDictionary: { [key: string]: {title: string, description: string, breakdown: {value: string, meaning: string}[]} } = {
    "STR": {
        "title": "STR (Strength)",
        "description": "Strength measures the muscle power of an investigator. The higher it is, the more the investigator can lift or tightly cling to something. This characteristic determines the damage an investigator inflicts in hand-to-hand combat. Reduced to STR 0, an investigator is an invalid, unable to get out of bed.",
        "breakdown": [
            {"value": "0", "meaning": "Enfeebled: unable to even stand up or lift a cup of tea."},
            {"value": "15", "meaning": "Puny, weak."},
            {"value": "50", "meaning": "Average human strength."},
            {"value": "90", "meaning": "One of the strongest people you’ve ever met."},
            {"value": "99", "meaning": "World-class (Olympic weightlifter). Human maximum."},
            {"value": "140", "meaning": "Beyond human strength (gorilla or horse)."},
        ]
    },
    "CON": {
        "title": "CON (Constitution)",
        "description": "Constitution represents health, vigor, and vitality. Poisons and diseases may directly challenge an investigator’s constitution. Investigators with a high constitution often have more hit points—the better to resist injury and attack. Serious physical injury or magical attack might lower the statistic, and if Constitution reaches zero the investigator dies.",
        "breakdown": [
            {"value": "0", "meaning": "Dead."},
            {"value": "1", "meaning": "Sickly, prone to prolonged illness and probably unable to operate without assistance."},
            {"value": "15", "meaning": "Weak health, prone to bouts of ill health, great propensity for feeling pain."},
            {"value": "50", "meaning": "Average healthy human."},
            {"value": "90", "meaning": "Shrugs off colds, hardy and hale."},
            {"value": "99", "meaning": "Iron constitution, able to withstand great amounts of pain. Human maximum."},
            {"value": "140", "meaning": "Beyond human constitution (e.g. elephant)."}
        ]
    },
    "SIZ": {
        "title": "SIZ (Size)",
        "description": "Size averages both height and weight into a single number. To see over a wall, to squeeze through a small opening, or even to judge whose head might be sticking up out of the grass, use size. Size helps determine hit points, damage bonus, and build. One might decrease SIZ to indicate the loss of a limb, though lowering DEX is more often the solution. Presumably if investigators lose all SIZ points they disappear—goodness knows to where!",
        "breakdown": [
            {"value": "1", "meaning": "A baby (1 to 12 pounds)."},
            {"value": "15", "meaning": "Child, very short in stature (dwarf) (33 pounds / 15 kg)."},
            {"value": "65", "meaning": "Average human size (moderate height and weight) (170 pounds / 75 kg)."},
            {"value": "80", "meaning": "Very tall, strongly built, or obese. (240 pounds / 110 kg)."},
            {"value": "99", "meaning": "Oversize in some respect (330 pounds / 150 kg)."},
            {"value": "150", "meaning": "Horse or cow (960 pounds / 436 kg)."},
            {"value": "180", "meaning": "Heaviest human ever recorded (1400 pounds / 634 kg)."}
        ]        
    },
    "DEX": {
        "title": "DEX (Dexterity)",
        "description": "Investigators with higher Dexterity scores are quicker, nimbler, and more physically flexible. A DEX roll might be made to grab a support to keep from falling, to move faster than an opponent, or to accomplish some delicate task. An investigator with zero DEX is uncoordinated and unable to perform physical tasks. In combat, the character with the highest DEX acts first.",
        "breakdown": [
            {"value": "0", "meaning": "Unable to move without assistance."},
            {"value": "15", "meaning": "Slow, clumsy with poor motor skills for fine manipulation."},
            {"value": "50", "meaning": "Average human dexterity."},
            {"value": "90", "meaning": "Fast, nimble and able to perform feats of fine manipulation (e.g. acrobat, great dancer)."},
            {"value": "99", "meaning": "World-class athlete (e.g. Olympic standard). Human maximum."},
            {"value": "120", "meaning": "Beyond human dexterity (e.g. tiger)."}
        ]        
    },
    "APP": {
        "title": "APP (Appearance)",
        "description": "Appearance measures both physical attractiveness and personality. A person with high APP is charming and likeable, but may lack conventional good looks. An investigator with APP of 0 is appallingly ugly or someone with a wholly detestable demeanor, provoking comment and shock everywhere. APP may be useful in social encounters or when trying to make a good impression.",
        "breakdown": [
            {"value": "0", "meaning": "So unsightly that others are affected by fear, revulsion, or pity."},
            {"value": "15", "meaning": "Ugly, possibly disfigured due to injury or at birth."},
            {"value": "50", "meaning": "Average human appearance."},
            {"value": "90", "meaning": "One of the most charming people you could meet, natural magnetism."},
            {"value": "99", "meaning": "The height of glamour and cool (supermodel or world renowned film star). Human maximum."}
        ]        
    },
    "INT": {
        "title": "INT (Intelligence)",
        "description": "Intelligence represents how well investigators learn, remember, analyze information, and solve complex puzzles. An investigator with zero INT is a babbling, drooling idiot. Intelligence determines the number of Personal Interest skill points (multiply INT × 2) allotted to a new investigator. INT also acts as the value for both Idea rolls and Intelligence rolls. If the amount of INT seems to contradict another characteristic, that’s another chance for roleplaying and further defining your investigator. For example, an investigator with high EDU and low INT might be a pedantic teacher or a sideshow performer, someone who knows facts but not their meanings. Conversely, high INT and low EDU might mean ignorance—such as an uneducated farm boy, new to the Big City—however this person would not be dull- witted.",
        "breakdown": [
            {"value": "0", "meaning": "No intellect, unable to comprehend the world around them."},
            {"value": "15", "meaning": "Slow learner, able to undertake only the most basic math, or read beginner-level books."},
            {"value": "50", "meaning": "Average human intellect."},
            {"value": "90", "meaning": "Quick-witted, probably able to comprehend multiple languages or theorems."},
            {"value": "99", "meaning": "Genius (Einstein, Da Vinci, Tesla, etc.). Human maximum."}
        ]        
    },
    "POW": {
        "title": "POW (Power)",
        "description": "Power indicates force of will: the higher the POW, the higher the aptitude for, and resistance to, magic. An investigator with zero POW is zombie-like and without purpose, as well as being unable to use magic. Unless stated otherwise, POW that is lost during the game is lost permanently. Sanity points (SAN) begin the game equal to the character’s POW. POW also dictates the character’s number of \"magic points,\" which, unlike POW, can be spent and regenerated during play. Magic points are equal to one-fifth of POW. The POW of ordinary characters and investigators rarely changes. However, those adroit in the mysteries of the magic of the Cthulhu Mythos may be able to increase their personal POW.",
        "breakdown": [
            {"value": "0", "meaning": "Enfeebled mind, no willpower or drive, no magical potential."},
            {"value": "15", "meaning": "Weak-willed, easily dominated by those with a greater intellect or willpower."},
            {"value": "50", "meaning": "Average human."},
            {"value": "90", "meaning": "Strong willed, driven, a high potential to connect with the unseen and magical."},
            {"value": "100", "meaning": "Iron will, strong connection to the spiritual 'realm' or unseen world."},
            {"value": "140", "meaning": "Beyond human, possibly alien."}
        ]        
    },
    "EDU": {
        "title": "EDU (Education)",
        "description": "Education is a measure of the formal and factual knowledge possessed by the investigator, as well as indicating the time the investigator has spent in full-time education. EDU measures retained information, not the intelligent application of that information (see Intelligence). An investigator without EDU would be like a newborn baby or an amnesiac—without knowledge of the world, probably very curious and credulous. An EDU of 60 suggests the investigator is a high school graduate, while a score of around 70 indicates a person with some college years. Those with an EDU greater than 80 have most likely conducted graduate level work and have a degree, as expected of a person who has been to a university of some kind. Note that sometimes a person with a high Education may not necessarily be schooled in the traditional sense, but rather may be self-taught and possess a highly studious and observant nature. EDU is a factor in determining how many Occupational skill points an investigator begins with, and represents the investigator’s starting percentage for the Own Language skill. EDU is also used when making Know rolls.",
        "breakdown": [
            {"value": "0", "meaning": "A newborn baby."},
            {"value": "15", "meaning": "Completely uneducated in every way."},
            {"value": "60", "meaning": "High school graduate."},
            {"value": "70", "meaning": "College graduate (Bachelor degree)."},
            {"value": "80", "meaning": "Degree level graduate (Master's degree)."},
            {"value": "90", "meaning": "Doctorate, professor."},
            {"value": "96", "meaning": "World-class authority in their field of study."},
            {"value": "99", "meaning": "Human maximum."}
        ]        
    },
}

export function StatInfo({ stat }: {stat: string}) {
    const [isShowing, setIsShowing] = useState(false)

    return (
        <Popover className="relative">
            <PopoverButton
                onMouseEnter={() => setIsShowing(true)}
                onMouseLeave={() => setIsShowing(false)}
                className="font-semibold text-blood"
            >
                {stat}
            </PopoverButton>
            <Transition
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
                show={isShowing}
            >
                <PopoverPanel 
                    anchor="bottom"
                    className="flex flex-col border border-charcoal/10 rounded-xl bg-white p-5 shadow-xl"
                    onMouseEnter={() => setIsShowing(true)}
                    onMouseLeave={() => setIsShowing(false)}
                >
                    {statDictionary[stat] != null ? (
                        <>
                            <h3 className="text-base font-semibold leading-7 text-gray-900">{statDictionary[stat].title}</h3>
                            <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-500">{statDictionary[stat].description}</p>
                            {(statDictionary[stat].breakdown != null && statDictionary[stat].breakdown.length > 0) ? (
                            <div>
                                <div className="mt-5 text-sm font-medium leading-6 text-gray-900">
                                    Examples:
                                </div>
                                {statDictionary[stat].breakdown.map(info =>
                                    <div key={info.value} className="ml-5 text-sm leading-6 text-gray-500 flex">
                                        <div className="w-[50px]">{info.value}</div>
                                        <div>{info.meaning}</div>
                                    </div>
                                )}
                            </div>
                            ): (<></>)}
                        </>
                    ) : (
                        <>
                            <h3 className="text-base font-semibold leading-7 text-gray-900">{stat}</h3>
                            <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-500">Note missing</p>
                        </>
                    )}
                </PopoverPanel>
            </Transition>
        </Popover>
    )
}