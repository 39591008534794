import { InvestigatorInfo } from "@/model/Investigator";

export default function InvestigatorInfoComponent({ info }: {info: InvestigatorInfo}) {
    return (
        <div className="bg-white shadow rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Investigator Info</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <p><span className="font-medium">Name:</span> {info.name}</p>
            <p><span className="font-medium">Age:</span> {info.age}</p>
            <p><span className="font-medium">Occupation:</span> {info.occupation}</p>
            <p><span className="font-medium">Nationality:</span> {info.nationality}</p>
            <p><span className="font-medium">Archetype:</span> {info.archetype}</p>
            <p><span className="font-medium">Talents:</span> {info.talents.join(', ')}</p>
          </div>
        </div>
      );
}