import Stat from "components/Stat";
import { CharacType } from "@/model/Investigator";

interface CharacteristicsProps {
    characteristics: CharacType;
}

export default function CharacteristicsComponent({ characteristics }: CharacteristicsProps) {
    return (
        <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4">Characteristics</h2>
            <div className="min-w-full text-right flex flex-wrap">
                {Object.entries(characteristics).map(([key, value]) => (
                    <Stat name={key} value={value} />
                ))}
            </div>
        </div>
    );
};