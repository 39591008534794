import { Investigator } from "@/model/Investigator"
import InvestigatorCard from "./InvestigatorCard";

interface InvestigatorListProps {
    investigatorList: Investigator[];
    onDelete: (id: String) => void;
}

export default function InvestigatorList({ investigatorList, onDelete }: InvestigatorListProps) {
    return (
        <div>
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                {investigatorList?.map((investigator, idx) =>
                    <li key={"investigator-id-" + idx} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                        <InvestigatorCard investigator={investigator} onDelete={onDelete}></InvestigatorCard>
                    </li>
                )}
            </ul>
        </div>
    )
}
