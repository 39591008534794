export default function Stat({ name, value }: {name: string, value: number}) {

    const hard = Math.floor(value / 2)
    const extreme = Math.floor(value / 5)

    return (
      <div className="stat-block">
        <div className="stat-checkbox"></div>
        <div className="stat-name">{name}</div>
        <div className="stat-value-block">
          <div className="stat-value-main">
          {value}
          </div>
          <div className="stat-value-secondary">
            <div className="stat-value-hard">{hard}</div>
            <div className="stat-value-extreme">{extreme}</div>
          </div>
        </div>
      </div>
    );
  }