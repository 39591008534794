import { CheckIcon } from '@heroicons/react/20/solid'
import { Link, useLocation } from 'react-router-dom'

const steps = [
    { id: '1', name: 'Pulp Archetype', href: '/builder/archetype'},
    { id: '2', name: 'Characteristics', href: '/builder/characteristics'},
    { id: '3', name: 'Pulp Talents', href: '/builder/talents'},
    { id: '4', name: 'Occupation', href: '/builder/occupation'},
    { id: '5', name: 'Archetype Skills', href: '/builder/arch-skills'},
    { id: '6', name: 'Occupation Skills', href: '/builder/occ-skills'},
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

type StepperInputType = {
    investigatorId: string | String
}

export default function Stepper({investigatorId}: StepperInputType) {

    const pathname = useLocation()
    let currentStep = steps.filter(it => pathname.pathname.endsWith(it.href))[0]

    if (currentStep === undefined) {
        currentStep = steps[0]
    }
    return (
        <nav aria-label="Progress" className='w-[192px] flex-none'>
            <div>
                Step {currentStep.id} out of {steps.length}
            </div>
            <ol role="list" className="overflow-hidden">
                {steps.map((step, stepIdx) => (
                    <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
                        {step.id < currentStep.id ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-500" />
                                ) : null}
                                <Link to={"/investigators/" + investigatorId + step.href} className="group relative flex items-start">
                                    <span className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-500 group-hover:bg-primary-800">
                                            <CheckIcon aria-hidden="true" className="h-5 w-5 text-white" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm text-primary-900">{step.name}</span>
                                    </span>
                                </Link>
                            </>
                        ) : step.id === currentStep.id ? (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                                ) : null}
                                <Link to={"/investigators/" + investigatorId + step.href} aria-current="step" className="group relative flex items-start">
                                    <span aria-hidden="true" className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-primary-500 bg-white">
                                            <span className="h-2.5 w-2.5 rounded-full bg-primary-500" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-primary-500">{step.name}</span>
                                    </span>
                                </Link>
                            </>
                        ) : (
                            <>
                                {stepIdx !== steps.length - 1 ? (
                                    <div aria-hidden="true" className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300" />
                                ) : null}
                                <Link to={"/investigators/" + investigatorId + step.href} className="group relative flex items-start">
                                    <span aria-hidden="true" className="flex h-9 items-center">
                                        <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                                        </span>
                                    </span>
                                    <span className="ml-4 flex min-w-0 flex-col">
                                        <span className="text-sm font-medium text-gray-500">{step.name}</span>
                                    </span>
                                </Link>
                            </>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    )
}
