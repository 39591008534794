'use client'
import { useContext, useState } from 'react'
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { ARCHETYPES } from './archetypes-data'
import { ButtonMultiSelect } from 'components/ButtonMultiSelect'
import Stat from 'components/Stat'
import { Button } from '@headlessui/react'
import { roll } from 'model/dice-util'
import { CharacType, SkillItem, calcBD, calcMOV } from 'model/Investigator'
import { useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { updateInvestigator } from 'api/investigators-api'

export default function Characteristics() {
  let navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0()
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  const [currentCharacteristics, setCurrentCharacteristics] = useState(investigator.characteristics)
  const [currentStats, setCurrentStats] = useState(investigator.stats)

  let archetype = ARCHETYPES.find(it => it.name === investigator.info.archetype)
  let mainCharacteristics: string[] = archetype ? archetype.characteristic : ARCHETYPES[0].characteristic
  const [selectedMainCharacteristic, setSelectedMainCharacteristic] = useState(mainCharacteristics[0] as keyof typeof investigator.characteristics)


  function rollCharacteristics() {
    let newCharac: CharacType = {
      STR: roll(3, 6) * 5,
      CON: roll(3, 6) * 5,
      SIZ: (roll(2, 6) + 6) * 5,
      DEX: roll(3, 6) * 5,
      APP: roll(3, 6) * 5,
      INT: (roll(2, 6) + 6) * 5,
      POW: roll(3, 6) * 5,
      EDU: (roll(2, 6) + 6) * 5,
    }
    newCharac[selectedMainCharacteristic] = (roll(1, 6) + 13) * 5
    setCurrentCharacteristics(newCharac)

    let newStat = {
      health: Math.floor((newCharac.STR + newCharac.SIZ) / 5),
      luck: (roll(2, 6) + 6) * 5,
      sanity: newCharac.POW,
      magic: Math.floor(newCharac.POW),
      BD: calcBD(newCharac.STR, newCharac.SIZ),
      MOV: calcMOV(newCharac.DEX, newCharac.STR, newCharac.SIZ),
    }
    setCurrentStats(newStat)
  }

  function lockCharacteristics() {
    let updatedInvestigator = { ...investigator }
    updatedInvestigator.characteristics = currentCharacteristics
    updatedInvestigator.stats = currentStats
    const index = updatedInvestigator.skills.findIndex(skill => skill.name === 'Dodge');
    (updatedInvestigator.skills[index] as SkillItem).value = Math.floor(updatedInvestigator.characteristics.DEX / 2)
    updatedInvestigator.initStep = 2
    updateInvestigator(getAccessTokenSilently(), updatedInvestigator)
    investigatorDispatch && investigatorDispatch({ type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: updatedInvestigator })
    navigate("../talents", { relative: "path" })
  }

  return (
    <div className="mx-20">
      <div className='flex flex-column'>
        <div className="mt-5 basis-3/4">
          <h3 className="text-base font-semibold leading-7 text-gray-900">
            Main Characteristic:&nbsp;
            {mainCharacteristics.length > 1 ? (
              <ButtonMultiSelect
                values={mainCharacteristics}
                selectedValue={selectedMainCharacteristic} onSelectionChange={it => setSelectedMainCharacteristic(it as keyof typeof investigator.characteristics)}></ButtonMultiSelect>
            ) : (
              <span>{mainCharacteristics[0]}</span>
            )
            }
          </h3>
          <Button className='mt-5 rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600' onClick={rollCharacteristics}>Roll the dice</Button>
        </div>
        <div className="my-5 basis-1/4">
          <Button type="button"
            className="float-right rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 data-[disabled]:bg-slate-100 data-[disabled]:text-slate-600"
            disabled={currentCharacteristics.CON === 0}
            onClick={lockCharacteristics}
          >
            Lock Characteristics
          </Button>
        </div>
      </div>
      <div className="mt-6 border-t border-gray-100">
        <div className="divide-y divide-gray-100 grid grid-cols-3">
          {Object.entries(currentCharacteristics).map(charac =>
            <Stat key={charac[0]} name={charac[0]} value={charac[1]}></Stat>
          )}
        </div>
      </div>
      <div className="mt-6">Health: {currentStats.health}</div>
      <div className="mt-6">Luck: {currentStats.luck}</div>
      <div className="mt-6">Sanity: {currentStats.sanity}</div>
      <div className="mt-6">Magic: {currentStats.magic}</div>
      <div className="mt-6">Bonus Damage: {currentStats.BD}</div>
      <div className="mt-6">MOV: {currentStats.MOV}</div>
    </div>
  );
}
