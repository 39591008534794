import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useParams } from "react-router-dom";
import { loadInvestigatorIfRequired } from 'api/investigator-service';
import { useAuth0 } from '@auth0/auth0-react';
import { updateInvestigator } from 'api/investigators-api';
import Stepper from './Stepper';

export default function Builder() {
    const investigator = useContext(InvestigatorContext)
    // Local state to handle the input value immediately
    const [nameInput, setNameInput] = useState(investigator.info.name);
    const investigatorDispatch = useContext(InvestigatorDispatchContext)
    const { getAccessTokenSilently } = useAuth0()
    let { id } = useParams();

    useEffect(() => {
        loadInvestigatorIfRequired(id, investigator, investigatorDispatch, getAccessTokenSilently())
    });

    // Effect to debounce the update to the investigator's name
    useEffect(() => {
        const handler = setTimeout(() => {
            if(investigator.info.name !== nameInput) {
                investigator.info.name = nameInput;
                updateInvestigator(getAccessTokenSilently(), investigator)
                investigatorDispatch && investigatorDispatch({ type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: investigator })
            }
        }, 500); // Adjust the delay as needed

        // Cleanup function to clear the timeout if nameInput changes
        return () => {
            clearTimeout(handler);
        };
    }, [nameInput]);

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => setNameInput(event.target.value)

    return (
        <div className="flex flex-col">
            <div>
                <label className="block text-sm font-medium leading-6 text-gray-900">
                    Investigator Name
                </label>
                <div className="mt-2">
                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={nameInput as string}
                        onChange={handleNameChange}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6"
                    />
                </div>
            </div>
            <div className="flex flex-row pt-6">
                <Stepper investigatorId={investigator.id}></Stepper>
                <div 
                    aria-label="Builder body"
                    className="bg-white shadow flex flex-col grow">
                    <Outlet />
                </div>
            </div>

        </div>
    );
}