import { Dispatch, createContext } from 'react';
import { Investigator } from 'model/Investigator';

export enum InvestigatorDispatchAction {
    SET_INVESTIGATOR,
    UPDATE_CHARACTERISTICS,
    UPDATE_STATS,
    UPDATE_TALENTS,
    UPDATE_OCCUPATION,
    UPDATE_SKILLS,
}

export type DispatchActionType = {
  type: InvestigatorDispatchAction;
  value: any;
}

export function investigatorReducer(investigator: Investigator, action: DispatchActionType): Investigator {
    switch (action.type) {
      case InvestigatorDispatchAction.SET_INVESTIGATOR: {
        return action.value;
      }
      default: {
        throw Error('Unknown action: ' + action);
      }
    }
}

export const InvestigatorContext = createContext<Investigator>(new Investigator());
export const InvestigatorDispatchContext = createContext<Dispatch<DispatchActionType>|null>(null);