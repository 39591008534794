import { SkillObjectType } from "@/components/SkillAdjuster";
import { SKILLS } from "./skills-data";
import { Skill, SpecializedSkill } from "./Investigator";
import { assert } from "../error-util";

export function calculateSkillsPoints(characteristics: any, expression: string): number {
  // Replace attribute names with their values
  let parsedExpression = expression
    .replace(/EDU/g, characteristics["EDU"].toString())
    .replace(/APP/g, characteristics["APP"].toString())
    .replace(/STR/g, characteristics["STR"].toString())
    .replace(/DEX/g, characteristics["DEX"].toString())
    .replace(/POW/g, characteristics["POW"].toString())
    .replace(/x/g, "*")

  // Handle the "or" logic
  parsedExpression = parsedExpression.replace(/\(([^)]+)\)/g, (_, p1) => {
    const options = p1.split(' or ').map((option: string) => eval(option))
    return Math.max(...options).toString();
  })

  // Evaluate the final expression
  return eval(parsedExpression.replace(/×/g, '*'))
}

const SKILL_NOT_SUPPORTED = ["Language Other (any)", "Survival (any)"];

export function findSkill<T extends { name: string; specializations?: { name: string }[] }>(skills: T[], skillName: string): T | null {
  var arrayToSearch = skills
  if (skillName.indexOf("(") !== -1 && SKILL_NOT_SUPPORTED.findIndex(s => s === skillName) === -1) {
    // This is a specialized skill
    const specializationName = skillName.split(" ")[0]
    const baseSkill = skills.find(skill => skill.name === specializationName)
    if (baseSkill && baseSkill?.specializations) {
      // Search within the specializations
      const specialization = baseSkill.specializations.find(s => s.name === skillName)
      return specialization as T || null;
    }
    return null
  } else {
    const skill = arrayToSearch.find(skill => skill.name === skillName)
    return skill || null
  }
}

export function createSkillObject(skillName: string, idx: number, dex: number, currentValue: number | null): SkillObjectType {
  var skillValue
  let minValue = (findSkill(SKILLS, skillName) as any)?.default_percentage
  minValue = minValue != null ? minValue : 0
  skillValue = currentValue == null ? minValue : currentValue
  return {
    idx: idx,
    skill: skillName,
    minValue: (typeof minValue === 'string') ? Math.floor(dex / 2) : minValue,
    currentValue: (typeof skillValue === 'string') ? Math.floor(dex / 2) : skillValue,
  }
}

export function expendSkills(skills: string[]): string[] {
  const expandedSkills: string[] = [];
  skills.forEach(skill => {
    if (skill.indexOf("(any)") !== -1 && SKILL_NOT_SUPPORTED.findIndex(s => s === skill) === -1) {
      const specializationName = skill.slice(0, -6)
      const specializations = SKILLS.find(s => s.name === specializationName)?.specializations
      expandedSkills.push(...specializations!.map(s => s.name))
    } else {
      expandedSkills.push(skill)
    }
  })
  return expandedSkills
}

export function compactSkills(skills: Skill[]): Skill[] {
  const result: Skill[] = []
  skills.forEach(skill => {
    if ('value' in skill) {
      result.push(skill)
    } else {
      // Case for SpecializedSkill
      if(skill.specializations.length > 1) {
        const newSpecializedSkill: SpecializedSkill = {name: skill.name, specializations: []}
        const specializations = SKILLS.find(s => s.name === skill.name)!.specializations
        assert(specializations !== null, `SKILLS.find should not return null with ${skill.name}`)
        var isOtherAdded = false
        skill.specializations.forEach(s => {
          const specializedSkill = specializations!.find(it => it.name === s.name)
          assert(specializedSkill !== null, `specializations.find should not return null. specializations=${specializations} name=${s.name}`)
          if (s.value !== specializedSkill!.default_percentage) {
            newSpecializedSkill.specializations.push(s)
          } else {
            if(!isOtherAdded) {
              isOtherAdded=true
            }
          }
        })

        if(isOtherAdded) {
          newSpecializedSkill.specializations.push({name: skill.name+" (others)", value: specializations![0].default_percentage})
        }

        if(newSpecializedSkill.specializations.length === 1) {
          newSpecializedSkill.specializations[0].name = skill.name+" (any)"
        }
        result.push(newSpecializedSkill)
      } else {
        result.push(skill)
      }
    }
  })
  return result
}