import React from "react";
import { Skill } from "model/Investigator";
import Stat from "components/Stat";

interface SkillsProps {
    skills: Skill[];
}

export default function SkillsComponent({ skills }: SkillsProps) {
    return (
        <div className="bg-white shadow rounded-lg p-6 overflow-x-auto">
            <h2 className="text-2xl font-semibold mb-4">Skills</h2>
            <div className="min-w-full text-left flex flex-col flex-wrap max-h-[2500px]">
                {skills.map((skill, index) => (
                    <SkillRow key={index} skill={skill} indentLevel={0} />
                ))}
            </div>
        </div>
    );
}

interface SkillRowProps {
    skill: Skill;
    indentLevel: number;
}

function SkillRow({ skill, indentLevel }: SkillRowProps) {
    const indentStyle = { paddingLeft: `${indentLevel * 20}px` };

    if ('value' in skill) {
        return (
            <div className="py-2 border-t text-right" style={indentStyle}><Stat name={skill.name} value={skill.value} /></div>
        );
    } else {
        return (
            <>
                <div className="border-t py-2 font-semibold"style={indentStyle}>{skill.name}</div>
                {skill.specializations.map((subSkill, subIndex) => (
                    <SkillRow
                        key={`${skill.name}-${subIndex}`}
                        skill={subSkill}
                        indentLevel={indentLevel + 1}
                    />
                ))}
            </>
        );
    }
}