
interface StatsProps {
    stats: {
        health: number;
        luck: number;
        sanity: number;
        magic: number;
        BD: number;
        MOV: number;
    };
}

export default function StatsComponent({ stats }: StatsProps) {
    return (
        <div className="bg-white shadow rounded-lg p-6">
            <h2 className="text-2xl font-semibold mb-4">Stats</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <p><span className="font-medium">Health:</span> {stats.health}</p>
                <p><span className="font-medium">Luck:</span> {stats.luck}</p>
                <p><span className="font-medium">Sanity:</span> {stats.sanity}</p>
                <p><span className="font-medium">Magic Points:</span> {stats.magic}</p>
                <p><span className="font-medium">Damage Bonus (BD):</span> {stats.BD}</p>
                <p><span className="font-medium">Movement Rate (MOV):</span> {stats.MOV}</p>
            </div>
        </div>
    );
};