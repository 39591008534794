'use client'
import { useNavigate } from "react-router-dom";
import { useContext, useState } from 'react'
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { ARCHETYPES } from './archetypes-data'
import { SkillAdjuster, SkillObjectType } from 'components/SkillAdjuster'
import { createSkillObject, expendSkills, findSkill } from 'model/skill-util'
import { updateInvestigator } from "api/investigators-api";
import { Investigator, Skill, SkillItem, updateSkills } from "model/Investigator";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "@headlessui/react";

export default function ArchetypeSkills() {
  let navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  const [pointToSpend, setPointToSpend] = useState(investigator.initStep >= 5 ? 0 : 100)


  let archetype = ARCHETYPES.find(it => it.name === investigator.info.archetype)
  let availableSkills: string[] = archetype ? archetype.skills : ARCHETYPES[0].skills
  availableSkills = expendSkills(availableSkills)
  const [skillList, setSkillList] = useState(availableSkills.map((it, idx) => {
    var skillValue = null
    if (investigator.initStep >= 5) {
      skillValue = (findSkill(investigator.skills, it) as SkillItem).value
    }
    return createSkillObject(it, idx, investigator.characteristics.DEX, skillValue)
  }))

  function lockCharacteristics() {
    let newSkillList: Skill[] = skillList.map(it => { return { name: it.skill, value: it.currentValue } })
    let updatedItem = { ...investigator } as Investigator
    updateSkills(updatedItem, newSkillList)
    updatedItem.initStep = 5
    updateInvestigator(getAccessTokenSilently(), updatedItem)
    investigatorDispatch && investigatorDispatch({ type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: updatedItem })
    navigate("../occ-skills", { relative: "path" })
  }

  function adjustValue(idx: number, modifier: number) {
    const newSkillList = [...skillList]
    newSkillList[idx].currentValue += modifier
    setSkillList(newSkillList)
    setPointToSpend(pointToSpend - modifier)
  }

  function skillAdjusterComponents(skillObject: SkillObjectType, idx: number) {
    const customTypeIndex = skillObject.skill.indexOf("(")
    if (customTypeIndex !== -1) {
      const skillCategoryName = skillObject.skill.substring(0, customTypeIndex - 1)

      return (
        <div>
          {idx ===0 || (idx !== 0 && skillList[idx-1].skill.indexOf("(") === -1) ?
            <p className="text-left">{skillCategoryName}</p>
            : <></>
          }
          <SkillAdjuster key={"skill-adjuster-" + idx} skillObject={skillObject} remainingToSpend={pointToSpend} onValueChange={adjustValue}></SkillAdjuster>  
        </div>
      )
    } else {
      return (
        <SkillAdjuster key={"skill-adjuster-" + idx} skillObject={skillObject} remainingToSpend={pointToSpend} onValueChange={adjustValue}></SkillAdjuster>
      )
    }
  }

  return (
    <div className="mx-20 my-10 w-11/12">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className='mb-8'>
          <h1 className="text-2xl font-semibold leading-1 text-gray-900">Adjust your {investigator.info.archetype} skills</h1>
          <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-500">You still have {pointToSpend} points to assign.</p>
        </div>
        <div className="mt-3 flex sm:ml-4 sm:mt-0">
          <Button type="button"
            className="relative top-5 right-5 rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 data-[disabled]:bg-slate-100 data-[disabled]:text-slate-600"
            disabled={pointToSpend !== 0}
            onClick={() => lockCharacteristics()}
          >
            Lock Archetype skills
          </Button>
        </div>
      </div>
      <div>
        {skillList?.map((skillObject, idx) => skillAdjusterComponents(skillObject, idx))}
      </div>
    </div>
  );
}
