import { Button } from '@headlessui/react'

export type SkillObjectType = {
    idx: number
    skill: string
    minValue: number
    currentValue: number
}

type SkillAdjusterInputType = {
    skillObject: SkillObjectType
    remainingToSpend: number
    onValueChange: (idx: number, modifier: number) => void
}

export function SkillAdjuster({ skillObject, remainingToSpend, onValueChange }: SkillAdjusterInputType) {

    const index = skillObject.skill.indexOf("(")
    const isSpecializedSkill = index !== -1 && isNaN(Number(skillObject.skill[index+1]))
    const skillName = isSpecializedSkill ? skillObject.skill.substring(index+1, skillObject.skill.length-1) : skillObject.skill

    function isButtonActive(skillObject: SkillObjectType, modifier: number): boolean {
        return (remainingToSpend - modifier) < 0
            || (skillObject.currentValue + modifier < skillObject.minValue)
            || (skillObject.currentValue + modifier > 95)
    }

    const categoryStyle = 'flex py-2 text-right' + (isSpecializedSkill ? " bg-gray-100" : "")
    const buttonStyle = "h-[30px] w-[30px] mx-1 rounded-md bg-slate-600 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 data-[disabled]:bg-slate-100 data-[disabled]:text-slate-600"
    return (
        <div className={categoryStyle}>
            <div className='w-1/5'>
                <span className="mr-3">{skillName}:</span>
            </div>
            <Button className={buttonStyle} disabled={isButtonActive(skillObject, -5)} onClick={() => onValueChange(skillObject.idx, -5)}>
                -5
            </Button>
            <Button className={buttonStyle} disabled={isButtonActive(skillObject, -1)} onClick={() => onValueChange(skillObject.idx, -1)}>
                -1
            </Button>
            <input
                key={"idx-" + skillObject.idx + "-" + skillObject.currentValue}
                defaultValue={skillObject.currentValue.toString()}
                disabled
                className="block w-[45px] rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-slate-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                placeholder={skillObject.currentValue.toString()}
            />
            <Button className={buttonStyle} disabled={isButtonActive(skillObject, 1)} onClick={() => onValueChange(skillObject.idx, 1)}>
                +1
            </Button>
            <Button className={buttonStyle} disabled={isButtonActive(skillObject, 5)} onClick={() => onValueChange(skillObject.idx, 5)}>
                +5
            </Button>
        </div>
    )
}