import { useAuth0 } from '@auth0/auth0-react';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from 'react';
import { createRouter } from './routes';



function App() {
  const { getAccessTokenSilently } = useAuth0()
  const token = getAccessTokenSilently();
  const router = React.useMemo(() => {
    return createBrowserRouter(createRouter(token));
  }, [token]);

  return (
    <RouterProvider router={router} />
  );
}

export default App;
