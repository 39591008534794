import { createInvestigator, deleteInvestigator, fetchInvestigatorList } from "api/investigators-api";
import InvestigatorList from "./InvestigatorList"
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useContext } from "react";
import { Investigator } from "model/Investigator";
import { useNavigate } from "react-router-dom";
import { InvestigatorDispatchAction, InvestigatorDispatchContext } from "./InvestigatorContext";

export default function Investigators() {
    const { getAccessTokenSilently } = useAuth0()
    const [investigatorList, setInvestigatorList] = useState<Investigator[]>([]);
    let navigate = useNavigate()
    const investigatorDispatch = useContext(InvestigatorDispatchContext)

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchInvestigatorList(getAccessTokenSilently());
            setInvestigatorList(data);
        };
        fetchData();
    }, []);

    const onCreate = () => {
        const create = async () => {
            const investigator =  await createInvestigator(getAccessTokenSilently())
            investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: investigator})
            navigate(investigator.id+'/builder', { relative: "path" })
        }
        create();
    }

    const handleDelete = (id: String) => {
        const deleteData = async () => {
            await deleteInvestigator(getAccessTokenSilently(), id);
            setInvestigatorList((prevList) =>
                prevList.filter((investigator) => investigator.id !== id)
            );
        }
        deleteData();
    };

    return (
        <div>
            <div>Your investigators</div>
            <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
                onClick={onCreate}
            >
                Create New Investigator
            </button>
            <InvestigatorList investigatorList={investigatorList} onDelete={handleDelete}></InvestigatorList>
        </div>
    )
}