export type OccupationDataType = {
    occupation: string,
    occupation_skill_points: string,
    credit_rating: string,
    suggested_contacts: string[],
    skills: OccupationDataSkillType[]
}

export type OccupationDataSkillType = string | OccupationDataSkillXOfType

export type OccupationDataSkillXOfType = { label: string, count: number, values: string[] }

export const OCCUPATIONS: OccupationDataType[] = [
    {
        "occupation": "Actor, Film",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "20-90",
        "suggested_contacts": ["film industry", "media critics", "writers"],
        "skills": [
            "Art and Craft (Acting)",
            "Disguise",
            "Drive Auto",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Actor, Stage and Radio",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "9-40",
        "suggested_contacts": ["theater industry", "newspaper arts critics", "actor's guild or union"],
        "skills": [
            "Art and Craft (Acting)",
            "Disguise",
            "Fighting",
            "History",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Agency Detective",
        "occupation_skill_points": "EDU x 2 + (STR x 2 or DEX x 2)",
        "credit_rating": "20-45",
        "suggested_contacts": ["local law enforcement", "clients"],
        "skills": [
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Fighting (Brawl)",
            "Firearms",
            "Law",
            "Library Use",
            "Psychology",
            "Stealth",
            "Track"
        ]
    },
    {
        "occupation": "Archeologist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "10-40",
        "suggested_contacts": ["patrons", "museums", "universities"],
        "skills": [
            "Appraise",
            "Archeology",
            "History",
            "Other Language (any)",
            "Library Use",
            "Spot Hidden",
            "Mechanical Repair",
            {
                "label": "Navigate or Science (e.g. chemistry, physics, geology, etc.)",
                "count": 1,
                "values": ["Navigate", "Science (Astronomy)", "Science (Biology)", "Science (Botany)", "Science (Chemistry)", "Science (Cryptography)", "Science (Engineering)", "Science (Forensics)", "Science (Geology)", "Science (Mathematics)", "Science (Meteorology)", "Science (Pharmacy)", "Science (Physics)", "Science (Zoology)"]
            },
        ]
    },
    {
        "occupation": "Artist",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or POW x 2)",
        "credit_rating": "9-50",
        "suggested_contacts": ["art galleries", "critics", "wealthy patrons", "the advertising industry"],
        "skills": [
            "Art and Craft (any)",
            {
                "label": "History or Natural World",
                "count": 1,
                "values": ["History", "Natural World"]
            },
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Other Language",
            "Psychology",
            "Spot Hidden",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Athlete",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-70",
        "suggested_contacts": ["sports personalities", "sports writers", "other media stars"],
        "skills": [
            "Climb",
            "Jump",
            "Fighting (Brawl)",
            "Ride",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Swim",
            "Throw",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Author",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["publishers", "critics", "historians, etc."],
        "skills": [
            "Art (Literature)",
            "History",
            "Library Use",
            {
                "label": "Occult or Natural World",
                "count": 1,
                "values": ["Occult", "Natural World"]
            },
            "Other Language",
            "Own Language",
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Aviator",
        "occupation_skill_points": "EDU x 2 + DEX x 2",
        "credit_rating": "30-60",
        "suggested_contacts": ["old military contacts", "other pilots", "airfield mechanics", "businessmen"],
        "skills": [
            "Accounting",
            "Electrical Repair",
            "Listen",
            "Mechanical Repair",
            "Navigate",
            "Pilot (Aircraft)",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Bank Robber",
        "occupation_skill_points": "EDU x 2 + (STR x 2 or DEX x 2)",
        "credit_rating": "5-75",
        "suggested_contacts": ["other gang members (current and retired)", "criminal freelancers", "organized crime"],
        "skills": [
            "Drive Auto",
            {
                "label": "Electrical or Mechanical Repair",
                "count": 1,
                "values": ["Electrical Repair", "Mechanical Repair"]
            },
            "Fighting",
            "Firearms",
            "Intimidate",
            "Locksmith",
            "Operate Heavy Machinery",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Bartender/Waitress",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "8-25",
        "suggested_contacts": ["regular customers", "possibly organized crime"],
        "skills": [
            "Accounting",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Fighting (Brawl)",
            "Listen",
            "Psychology",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Beat Cop",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["law enforcement", "local businesses and residents", "street level crime", "organized crime"],
        "skills": [
            "Fighting (Brawl)",
            "Firearms",
            "First Aid",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Law",
            "Psychology",
            "Spot Hidden",
            {
                "label": "One of the following as a personal specialty: Drive Autom or Ride",
                "count": 1,
                "values": ["Drive Auto", "Ride"]
            }
        ]
    },
    {
        "occupation": "Big Game Hunter",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "20-50",
        "suggested_contacts": ["foreign government officials", "game wardens", "past (usually wealthy) clients", "black-market gangs and traders", "zoo owners"],
        "skills": [
            "Firearms (any)",
            {
                "label": "Listen or Spot Hidden",
                "count": 1,
                "values": ["Listen", "Spot Hidden"]
            },
            "Natural World",
            "Navigate",
            "Other Language or Survival (any)",
            {
                "label": "Science (Biology or Botany)",
                "count": 1,
                "values": ["Biology", "Botany"]
            },
            "Stealth",
            "Track"
        ]
    },
    {
        "occupation": "Bounty Hunter",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["bail bondsmen", "local police", "criminal informants"],
        "skills": [
            "Drive Auto",
            {
                "label": "Electrical or Mechanical Repair",
                "count": 1,
                "values": ["Electrical Repair", "Mechanical Repair"]
            },
            "Fighting or Firearms",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Law",
            "Psychology",
            "Track",
            "Stealth"
        ]
    },
    {
        "occupation": "Boxer/Wrestler",
        "occupation_skill_points": "EDU x 2 + STR x 2",
        "credit_rating": "9-60",
        "suggested_contacts": ["sports promoters", "journalists", "organized crime", "professional trainers"],
        "skills": [
            "Dodge",
            "Fighting (Brawl)",
            "Intimidate",
            "Jump",
            "Psychology",
            "Spot Hidden",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Butler",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-40 (dependent on their employer’s status and credit rating)",
        "suggested_contacts": ["waiting staff of other households", "local businesses", "household suppliers"],
        "skills": [
            "Accounting or Appraise",
            "Art and Craft (any, e.g. Cook, Tailor, Barber)",
            "First Aid",
            "Listen",
            "Other Language",
            "Psychology",
            "Spot Hidden",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Cat Burglar",
        "occupation_skill_points": "EDU x 2 + DEX x 2",
        "credit_rating": "5-40",
        "suggested_contacts": ["fences", "other burglars"],
        "skills": [
            "Appraise",
            "Climb",
            {
                "label": "Electrical or Mechanical Repair",
                "count": 1,
                "values": ["Electrical Repair", "Mechanical Repair"]
            },
            "Listen",
            "Locksmith",
            "Sleight of Hand",
            "Stealth",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Chauffeur",
        "occupation_skill_points": "EDU x 2 + DEX x 2",
        "credit_rating": "10-40",
        "suggested_contacts": ["successful business people (criminals included)", "political representatives"],
        "skills": [
            "Drive Auto",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Listen",
            "Mechanical Repair",
            "Navigate",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Confidence Trickster",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "10-65",
        "suggested_contacts": ["other confidence artists", "freelance criminals"],
        "skills": [
            "Appraise",
            "Art and Craft (Acting)",
            "Law or Other Language",
            "Listen",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Sleight of Hand"
        ]
    },
    {
        "occupation": "Criminal",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or APP x 2)",
        "credit_rating": "5-65",
        "suggested_contacts": ["other criminals", "organized crime", "law enforcement", "street thugs", "private detectives"],
        "skills": [
            "Art and Craft (any) or Disguise",
            "Appraise",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Fighting or Firearms",
            "Locksmith or Mechanical Repair",
            "Stealth",
            "Psychology",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Cult Leader",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "30-60",
        "suggested_contacts": ["while the majority of followers will be 'regular' people, the more charismatic the leader, the greater the possibility of celebrity followers, such as movie stars and rich widows"],
        "skills": [
            "Accounting",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Occult",
            "Psychology",
            "Spot Hidden",
            "any two other skills as specialties"
        ]
    },
    {
        "occupation": "Dilettante",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "50-99",
        "suggested_contacts": ["variable, but usually people of a similar background and tastes", "fraternal organizations", "bohemian circles", "high society at large"],
        "skills": [
            "Art and Craft (Any)",
            "Firearms",
            "Other Language",
            "Ride",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "any three other skills as personal or era specialties"
        ]
    },
    {
        "occupation": "Doctor of Medicine",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "30-80",
        "suggested_contacts": ["other physicians", "medical workers", "patients", "ex-patients"],
        "skills": [
            "First Aid",
            "Medicine",
            "Other Language (Latin)",
            "Psychology",
            "Science (Biology and Pharmacy)",
            {
                "label": "Two other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Drifter",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2 or STR x 2)",
        "credit_rating": "0-5",
        "suggested_contacts": ["other hobos", "a few friendly railroad guards", "soft touches in numerous towns"],
        "skills": [
            "Climb",
            "Jump",
            "Listen",
            "Navigate",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Stealth",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Elected Official",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "50-90",
        "suggested_contacts": ["political operatives", "government", "news media", "business", "foreign governments", "possibly organized crime"],
        "skills": [
            "Charm",
            "History",
            "Intimidate",
            "Fast Talk",
            "Listen",
            "Own Language",
            "Persuade",
            "Psychology"
        ]
    },
    {
        "occupation": "Engineer",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "30-60",
        "suggested_contacts": ["business or military workers", "local government", "architects"],
        "skills": [
            "Art and Craft (Technical Drawing)",
            "Electrical Repair",
            "Library Use",
            "Mechanical Repair",
            "Operate Heavy Machine",
            "Science (Chemistry and Physics)",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Entertainer",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "9-70",
        "suggested_contacts": ["Vaudeville", "theater", "film industry", "entertainment critics", "organized crime", "television (for modern-day)"],
        "skills": [
            "Art and Craft (e.g. Acting, Singer, Comedian, etc.)",
            "Disguise",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Listen",
            "Psychology",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Exorcist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "25-55",
        "suggested_contacts": ["Religious organizations"],
        "skills": [
            "Anthropology",
            "History",
            "Library Use",
            "Listen",
            "Occult",
            "Other Language",
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Explorer",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2 or STR x 2)",
        "credit_rating": "55-80",
        "suggested_contacts": ["major libraries", "universities", "museums", "wealthy patrons", "other explorers", "publishers", "foreign government officials", "local tribespeople"],
        "skills": [
            {
                "label": "Climb or Swim",
                "count": 1,
                "values": ["Climb", "Swim"]
            },
            "Firearms (any)",
            "History",
            "Jump",
            "Natural World",
            "Navigate",
            "Other Language",
            "Survival"
        ]
    },
    {
        "occupation": "Federal Agent",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "20-40",
        "suggested_contacts": ["federal agencies", "law enforcement", "organized crime"],
        "skills": [
            "Drive Auto",
            "Fighting (Brawl)",
            "Firearms",
            "Law",
            "Persuade",
            "Stealth",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Gambler",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2)",
        "credit_rating": "8-50",
        "suggested_contacts": ["bookies", "organized crime", "street scene"],
        "skills": [
            "Accounting",
            "Art and Craft (Acting)",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Listen",
            "Psychology",
            "Sleight of Hand",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Gangster, Boss",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "60-95",
        "suggested_contacts": ["organized crime", "street-level crime", "police", "city government", "politicians", "judges", "unions", "lawyers", "businesses and residents of the same ethnic community"],
        "skills": [
            "Fighting",
            "Firearms",
            "Law",
            "Listen",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Gangster, Underling",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-20",
        "suggested_contacts": ["street-level crime", "police", "businesses and residents of the same ethnic community"],
        "skills": [
            "Drive Auto",
            "Fighting",
            "Firearms",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Get-Away Driver",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-20",
        "suggested_contacts": ["customers", "businesses", "law enforcement", "general street-level life"],
        "skills": [
            "Accounting",
            "Drive Auto",
            "Listen",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Mechanical Repair",
            "Navigate",
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Gun Moll",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "10-80 (income is usually dependent on boyfriend’s income)",
        "suggested_contacts": ["gangsters", "law enforcement", "local businesses"],
        "skills": [
            "Art and Craft (any)",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            {
                "label": "Fighting (Brawl) or Firearms (Handgun)",
                "count": 1,
                "values": ["Fighting (Brawl)", "Firearms (Handgun)"]
            },
            "Drive Auto",
            "Listen",
            "Stealth",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Gentleman/Lady",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "40-90",
        "suggested_contacts": ["upper classes and landed gentry", "politics", "servants and agricultural workers"],
        "skills": [
            "Art and Craft (any)",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            {
                "label": "Firearms (Rifle/Shotgun)",
                "count": 1,
                "values": ["Firearms (Rifle)", "Firearms (Shotgun)"]
            },
            "History",
            "Other Language (any)",
            "Navigate",
            "Ride"
        ]
    },
    {
        "occupation": "Hired Muscle",
        "occupation_skill_points": "EDU x 2 + STR x 2",
        "credit_rating": "5-30",
        "suggested_contacts": ["organized crime", "street-level law enforcement", "local traders"],
        "skills": [
            "Drive Auto",
            "Fighting",
            "Firearms",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Stealth",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Hit Man/Woman",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "30-60",
        "suggested_contacts": ["few, mostly underworld; people prefer not to know them too well", "The best will have earned a formidable reputation on the street"],
        "skills": [
            "Disguise",
            "Electrical Repair",
            "Fighting",
            "Firearms",
            "Locksmith",
            "Mechanical Repair",
            "Stealth",
            "Psychology"
        ]
    },
    {
        "occupation": "Hobo",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2)",
        "credit_rating": "0-5",
        "suggested_contacts": ["other hobos", "a few friendly railroad guards", "soft touches in numerous towns"],
        "skills": [
            "Art and Craft (any)",
            "Climb",
            "Jump",
            "Listen",
            {
                "label": "Locksmith or Sleight of Hand",
                "count": 1,
                "values": ["Locksmith", "Sleight of Hand"]
            },
            "Navigate",
            "Stealth",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Hooker",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "5-50",
        "suggested_contacts": ["street scene", "police", "possibly organized crime", "personal clientele"],
        "skills": [
            "Art and Craft (any)",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Dodge",
            "Psychology",
            "Sleight of Hand",
            "Stealth",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Itinerant Worker",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "0-10",
        "suggested_contacts": ["hobos", "drivers", "farmers", "law enforcement"],
        "skills": [
            "Art and Craft (any)",
            "Climb",
            "Sleight of Hand",
            "Mechanical Repair",
            "Natural World",
            "Navigate",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Investigative Journalist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["news industry", "politicians", "street-level crime or law enforcement"],
        "skills": [
            {
                "label": "Art and Craft (Art or Photography)",
                "count": 1,
                "values": ["Art and Craft (Fine Art)", "Art and Craft (Photographer)"]
            },
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "History",
            "Library Use",
            "Own Language",
            "Psychology",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Laborer",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "5-20",
        "suggested_contacts": ["other workers and supervisors within their industry"],
        "skills": [
            "Drive Auto",
            "Electrical Repair",
            "Fighting (Brawl)",
            "First Aid",
            "Mechanical Repair",
            "Operate Heavy Machinery",
            "Throw",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Librarian",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-35",
        "suggested_contacts": ["booksellers", "community groups", "specialist researchers"],
        "skills": [
            "Accounting",
            "Library Use",
            "Other Language",
            "Own Language",
            {
                "label": "Four other skills as a personal or era specialties",
                "count": 4,
                "values": []
            }
        ]
    },
    {
        "occupation": "Mechanic",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-40",
        "suggested_contacts": ["Union members", "trade-relevant specialists"],
        "skills": [
            "Art and Craft (any)",
            "Climb",
            "Drive Auto",
            "Electrical Repair",
            "Mechanical Repair",
            "Operate Heavy Machinery",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Military Officer",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "20-70",
        "suggested_contacts": ["military", "federal government"],
        "skills": [
            "Accounting",
            "Firearms",
            "Navigate",
            "First Aid",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Missionary",
        "occupation_skill_points": "EDU x 2 + APP x 2",
        "credit_rating": "0-30",
        "suggested_contacts": ["church hierarchy", "foreign officials"],
        "skills": [
            "Art and Craft (any)",
            "First Aid",
            "Mechanical Repair",
            "Medicine",
            "Natural World",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Musician",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["club owners", "musicians’ union", "organized crime", "street-level criminals"],
        "skills": [
            "Art and Craft (Instrument)",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Listen",
            "Psychology",
            {
                "label": "Four other skills as a personal or era specialties",
                "count": 4,
                "values": []
            }
        ]
    },
    {
        "occupation": "Nurse",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["hospital workers", "physicians", "community workers"],
        "skills": [
            "First Aid",
            "Listen",
            "Medicine",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            
            {
                "label": "Science (Biology or Chemistry)",
                "count": 1,
                "values": ["Science (Biology)", "Science (Chemistry)"]
            },
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Occultist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "10-80",
        "suggested_contacts": ["libraries", "occult societies or fraternities", "other occultists"],
        "skills": [
            "Anthropology",
            "History",
            "Library Use",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Occult",
            "Other Language",
            "Science (Astronomy)",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Parapsychologist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["universities", "parapsychological societies", "clients"],
        "skills": [
            "Anthropology",
            "Art and Craft (Photographer)",
            "History",
            "Library Use",
            "Occult",
            "Other Language",
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Photographer",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["advertising industry", "local clients (including political organizations and newspapers)"],
        "skills": [
            "Art and Craft (Photographer)",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Science (Chemistry)",
            "Stealth",
            "Spot Hidden",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Police Detective",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "20-50",
        "suggested_contacts": ["law enforcement", "street-level crime", "coroner’s office", "judiciary", "organized crime"],
        "skills": [
            {
                "label": "Art and Craft (Acting) or Disguise",
                "count": 1,
                "values": ["Art and Craft (Acting)", "Disguise"]
            },
            "Firearms",
            "Law",
            "Listen",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Priest",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-60",
        "suggested_contacts": ["church hierarchy", "local congregations", "community leaders"],
        "skills": [
            "Accounting",
            "History",
            "Library Use",
            "Listen",
            "Other Language",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Private Investigator",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["law enforcement", "clients"],
        "skills": [
            "Art and Craft (Photographer)",
            "Disguise",
            "Law",
            "Library Use",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Spot Hidden",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Professor",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "20-70",
        "suggested_contacts": ["scholars", "universities", "libraries"],
        "skills": [
            "Library Use",
            "Other Language",
            "Own Language",
            "Psychology",
            {
                "label": "Four other skills as a personal or era specialties",
                "count": 4,
                "values": []
            }
        ]
    },
    {
        "occupation": "Ranger",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "5-20",
        "suggested_contacts": ["local people and native folk", "traders"],
        "skills": [
            "Firearms",
            "First Aid",
            "Listen",
            "Natural World",
            "Navigate",
            "Spot Hidden",
            "Survival (any)",
            "Track"
        ]
    },
    {
        "occupation": "Reporter",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-30",
        "suggested_contacts": ["news and media industries", "political organizations and government", "business", "law enforcement", "street criminals", "high and low society"],
        "skills": [
            "Art and Craft (Acting)",
            "History",
            "Listen",
            "Own Language",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Stealth",
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Sailor",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["military", "veterans’ associations"],
        "skills": [
            {
                "label": "Electrical or Mechanical Repair",
                "count": 1,
                "values": ["Electrical Repair", "Mechanical Repair"]
            },
            "Fighting",
            "Firearms",
            "First Aid",
            "Navigate",
            "Pilot (Boat)",
            "Survival (Sea)",
            "Swim"
        ]
    },
    {
        "occupation": "Scientist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "9-50",
        "suggested_contacts": ["other scientists and academics", "universities", "their employers and former employers"],
        "skills": [
            {
                "label": "Any three science specialisms",
                "count": 3,
                "values": ["Science (Astronomy)", "Science (Biology)", "Science (Botany)", "Science (Chemistry)", "Science (Cryptography)", "Science (Engineering)", "Science (Forensics)", "Science (Geology)", "Science (Mathematics)", "Science (Meteorology)", "Science (Pharmacy)", "Science (Physics)", "Science (Zoology)"]
            },
            {
                "label": "Computer Use or Library Use",
                "count": 1,
                "values": ["Computer Use", "Library Use"]
            },
            "Other Language",
            "Own Language",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Spot Hidden"
        ]
    },
    {
        "occupation": "Secretary",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or APP x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["other office workers", "senior executives in client firms"],
        "skills": [
            "Accounting",
            {
                "label": "Art and Craft (Writter or Forgery)",
                "count": 1,
                "values": ["Art and Craft (Forgery)", "Art and Craft (Writter)"]
            },
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Own Language",
            {
                "label": "Computer Use or Library Use",
                "count": 1,
                "values": ["Computer Use", "Library Use"]
            },
            "Psychology",
            {
                "label": "One other skill as a personal or era specialty",
                "count": 1,
                "values": []
            }
        ]
    },
    {
        "occupation": "Soldier",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "9-30",
        "suggested_contacts": ["military", "veterans’ associations"],
        "skills": [
            {
                "label": "Climb or Swim",
                "count": 1,
                "values": ["Climb", "Swim"]
            },
            "Dodge",
            "Fighting",
            "Firearms",
            "Stealth",
            "Survival",
            {
                "label": "two of the following: First Aid, Mechanical Repair or Other Language",
                "count": 2,
                "values": ["First Aid", "Mechanical Repair", "Other Language"]
            },
            
        ]
    },
    {
        "occupation": "Spy",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or DEX x 2)",
        "credit_rating": "20-60",
        "suggested_contacts": ["generally only the person the spy reports to", "other connections developed while undercover"],
        "skills": [
            {
                "label": "Art and Craft (Acting) or Disguise",
                "count": 1,
                "values": ["Art and Craft (Acting)", "Disguise"]
            },
            "Firearms",
            "Listen",
            "Other Language",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Sleight of Hand",
            "Stealth"
        ]
    },
    {
        "occupation": "Street Punk",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "3-10",
        "suggested_contacts": ["petty criminals", "other punks", "the local fence", "maybe the local gangster", "certainly the local police"],
        "skills": [
            "Climb",
            {
                "label": "One interpersonal skill",
                "count": 1,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Fighting",
            "Firearms",
            "Jump",
            "Sleight of Hand",
            "Stealth",
            "Throw"
        ]
    },
    {
        "occupation": "Student/Intern",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "5-10",
        "suggested_contacts": ["academics and other students", "while interns may also know business people"],
        "skills": [
            "Language (Own or Other)",
            "Library Use",
            "Listen",
            "three fields of study",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ]
    },
    {
        "occupation": "Tribe Member",
        "occupation_skill_points": "EDU x 2 + (DEX x 2 or STR x 2)",
        "credit_rating": "0-15",
        "suggested_contacts": ["fellow tribe members"],
        "skills": [
            "Climb",
            {
                "label": "Fighting or Throw",
                "count": 1,
                "values": ["Fighting", "Throw"]
            },
            "Listen",
            "Natural World",
            "Occult",
            "Spot Hidden",
            "Swim",
            "Survival (any)"
        ]
    },
    {
        "occupation": "Union Activist",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "5-30",
        "suggested_contacts": ["other labor leaders and activists", "political friends", "possibly organized crime", "in the 1920s, also socialists, communists, and subversive anarchists"],
        "skills": [
            "Accounting",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Fighting (Brawl)",
            "Law",
            "Listen",
            "Operate Heavy Machinery",
            "Psychology"
        ]
    },
    {
        "occupation": "Yogi",
        "occupation_skill_points": "EDU x 4",
        "credit_rating": "6-60",
        "suggested_contacts": ["tribespeople", "occult or spiritual fraternities", "wealthy patrons"],
        "skills": [
            "First Aid",
            "History",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Natural World",
            "Occult",
            "Other Language",
            {
                "label": "Tow other skills as a personal or era specialties",
                "count": 2,
                "values": []
            }
        ],
    },
    {
        "occupation": "Zealot",
        "occupation_skill_points": "EDU x 2 + (APP x 2 or POW x 2)",
        "credit_rating": "0-30",
        "suggested_contacts": ["religious or fraternal groups", "news media"],
        "skills": [
            "History",
            {
                "label": "Two interpersonal skills",
                "count": 2,
                "values": ["Charm", "Fast Talk", "Intimidate", "Persuade"]
            },
            "Psychology",
            "Stealth",
            {
                "label": "Three other skills as a personal or era specialties",
                "count": 3,
                "values": []
            }
        ]
    }
]