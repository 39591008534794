import { Investigator } from "@/model/Investigator"

const ENDPOINT: string = process.env.REACT_APP_LAMBDA_ENDPOINT!!+"/investigator"

async function generateHeader(token: Promise<String>): Promise<any> {
  return { 'Content-Type': 'application/json', 'Authorization': `Bearer ${await token}`}
}

export async function fetchInvestigatorList(token: Promise<String>): Promise<Investigator[]> {
  let req = {
    method: 'GET', 
    headers: await generateHeader(token),
  }

  let res = await fetch(ENDPOINT, req)

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return (await res.json()) as Investigator[];
}

export async function createInvestigator(token: Promise<String>): Promise<Investigator> {
  let req = {
    method: 'POST', 
    headers: await generateHeader(token)
  }
  let res = await fetch(ENDPOINT, req)

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return (await res.json()) as Investigator;
}

export async function readInvestigator(token: Promise<String>, id: String): Promise<Investigator> {
  let req = {
    method: 'GET', 
    headers: await generateHeader(token),
  }
  let res = await fetch(ENDPOINT+"/"+id, req)

  if (!res.ok) {
    throw new Error(res.statusText);
  }

  return (await res.json()) as Investigator;
}

export async function updateInvestigator(token: Promise<String>, investigator: Investigator) {
  let req = {
    method: 'PUT', 
    headers: await generateHeader(token),
    body: JSON.stringify(investigator)
  }
  let res = await fetch(ENDPOINT, req)

  if (!res.ok) {
    throw new Error(res.statusText);
  }
}

export async function deleteInvestigator(token: Promise<String>, id: String) {
  let req = {
    method: 'DELETE', 
    headers: await generateHeader(token),
  }
  let res = await fetch(ENDPOINT+"/"+id, req)

  if (!res.ok) {
    throw new Error(res.statusText);
  }
}