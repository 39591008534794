'use client'
import { useNavigate } from "react-router-dom";
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { useContext, useState } from 'react'
import { calculateSkillsPoints, createSkillObject } from 'model/skill-util'
import { OccupationDataSkillXOfType, OCCUPATIONS } from './occupation-data'
import { SkillAdjuster, SkillObjectType } from 'components/SkillAdjuster'
import { updateInvestigator } from "api/investigators-api";
import { Skill, SkillItem, updateSkills } from "model/Investigator";
import { useAuth0 } from "@auth0/auth0-react";

export default function OccupationSkills() {
  let navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  const selectedOccupation = getSelectedOccupation()

  function getSelectedOccupation() {
    if (investigator?.info?.occupation === null || investigator.info.occupation === "") {
      return OCCUPATIONS[0]
    } else {
      return OCCUPATIONS.find(it => it.occupation === investigator.info.occupation)!!
    }
  }

  const [pointToSpend, setPointToSpend] = useState(calculateSkillsPoints(investigator.characteristics, selectedOccupation.occupation_skill_points))
  const skillListInit: SkillObjectType[] = []
  selectedOccupation.skills.forEach((it, idx) => {
    if (typeof it === 'string') {
      const skill = investigator.skills.find(skill => skill.name === it) as SkillItem
      skillListInit.push(createSkillObject(it, idx, investigator.characteristics.DEX, skill?.value))
    } else {
      const values = (it as OccupationDataSkillXOfType).values
      if(values.length > 0) {
        values.forEach(val => {
          const skill = investigator.skills.find(skill => skill.name === val) as SkillItem
          skillListInit.push(createSkillObject(val, idx, investigator.characteristics.DEX, skill?.value))
        })
      }
    }
  })
  const [skillList, setSkillList] = useState(skillListInit)
  const [creditRating, setCreditRating] = useState({
    idx: 666,
    skill: "Credit Rating ("+selectedOccupation.credit_rating+")",
    minValue: 0,
    currentValue: (investigator.skills.find(s => s.name === "Credit Rating")! as SkillItem).value
  })


  function lockCharacteristics() {
    let newSkillList: Skill[] = skillList.map(it => {return {name: it.skill, value: it.currentValue}})
    newSkillList.push({name: "Credit Rating", value: creditRating.currentValue})
    let updatedItem = {...investigator}
    updateSkills(updatedItem, newSkillList)
    updatedItem.initStep = 6
    updateInvestigator(getAccessTokenSilently(), updatedItem)
    investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: updatedItem})
    navigate("../..", { relative: "path" })
  }

  function adjustValue(idx: number, modifier: number) {
    const newSkillList = [...skillList]
    newSkillList[idx].currentValue += modifier
    setSkillList(newSkillList)
    setPointToSpend(pointToSpend - modifier)
  }

  function adjustCreditRating(idx: number, modifier: number) {
    const newCreditRating = {...creditRating}
    newCreditRating.currentValue += modifier
    setCreditRating(newCreditRating)
  }

  function skillAdjusterComponents(skillObject: SkillObjectType, idx: number) {
    const customTypeIndex = skillObject.skill.indexOf("(")
    if (customTypeIndex !== -1) {
      const skillCategoryName = skillObject.skill.substring(0, customTypeIndex - 1)

      return (
        <div>
          {idx ===0 || (idx !== 0 && skillList[idx-1].skill.indexOf("(") === -1) ?
            <p className="text-left">{skillCategoryName}</p>
            : <></>
          }
          <SkillAdjuster key={"occ-skill-" + idx} skillObject={skillObject} remainingToSpend={pointToSpend} onValueChange={adjustValue}></SkillAdjuster>  
        </div>
      )
    } else {
      return (
        <SkillAdjuster key={"occ-skill-" + idx} skillObject={skillObject} remainingToSpend={pointToSpend} onValueChange={adjustValue}></SkillAdjuster>
      )
    }
  }

  return (
    <div className="mx-20 my-10">
      <div className="sm:flex sm:items-center sm:justify-between">
        <div className='mb-8'>
          <h1 className="text-2xl font-semibold leading-1 text-gray-900">Adjust your {investigator.info.occupation} skills</h1>
          <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-500">You still have {pointToSpend} points to assign.</p>
        </div>
        <div className="mt-3 flex sm:ml-4 sm:mt-0">
          <button type="button"
            className="relative top-5 right-5 rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            onClick={() => lockCharacteristics()}
          >
            Lock Occupation skills
          </button>
        </div>
      </div>
      <div className="border-b border-gray-200 pb-2">
        <SkillAdjuster key={"occ-skill-credit-rating"} skillObject={creditRating} remainingToSpend={100} onValueChange={adjustCreditRating}></SkillAdjuster>
      </div>
      <div>
        {skillList?.map((skillObject, idx) => skillAdjusterComponents(skillObject, idx))}
      </div>
    </div>
  );
}
