import Header from 'components/Header';
import { Outlet } from "react-router-dom";


export default function Home() {

  return (
    <div className="App bg-neutral-100 min-h-screen">
      <Header></Header>
      <div className="mx-auto max-w-7xl p-6 lg:px-8">
        <Outlet />
      </div>
    </div>
  );
}