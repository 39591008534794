'use client'
import { StatInfo } from 'components/StatInfo'
import { useContext, useState } from 'react'
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { ARCHETYPES } from './archetypes-data'
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react'
import { updateInvestigator } from 'api/investigators-api'

export default function Archetype() {
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  let navigate = useNavigate()
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0()
  const [selectedArchetype, setSelectedArchetype] = useState(getInitialState())

  function getInitialState() {
    if (investigator?.info?.archetype === null || investigator.info.archetype === "") {
      return ARCHETYPES[0]
    } else {
      return ARCHETYPES.find(it => it.name === investigator.info.archetype)!!
    }
  }

  function selectArchetype() {
    let updatedItem = { ...investigator }
    updatedItem.info.archetype = selectedArchetype.name
    updatedItem.initStep = 1
    updateInvestigator(getAccessTokenSilently(), updatedItem)
    investigatorDispatch && investigatorDispatch({
      type: InvestigatorDispatchAction.SET_INVESTIGATOR,
      value: updatedItem
    })
    if (location.pathname.endsWith('/builder')) {
      navigate("characteristics", { relative: "path" })
    } else {
      navigate("../characteristics", { relative: "path" })
    }
  }

  function getArchetypeDetailRowComponent(title: string, list: [], defaultText = "") {
    return (
      <div className="flex w-full px-4 py-6">
        <div className="flex w-1/4 text-sm font-medium text-gray-900">{title}</div>
        <div className="flex w-3/4 ml-2 flex-wrap text-sm text-gray-700">
          {list.length > 0 ? (list.map((item, idx) => (
            <span className="flex" key={idx}>
              <StatInfo stat={item}></StatInfo>
              {(list.length > 1 && idx !== list.length - 1) ? (<p>,&nbsp;</p>) : (<></>)}
            </span>
          ))) : (<p>{defaultText}</p>)
          }
        </div>
      </div>
    )
  }

  function getSelectedArchetypeComponent(arch: any) {
    return (
      <div className="mx-20">
        <div className='flex flex-column'>
          <div className="my-5 basis-3/4">
            <h3 className="text-2xl font-semibold leading-7 text-gray-900">{arch.name}</h3>
            <p className="mt-1 max-w-3xl text-sm leading-6 text-gray-500">{arch.description}</p>
          </div>
          <div className="my-5 basis-1/4">
            <button type="button"
              className="float-right rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              onClick={selectArchetype}
            >
              Select this archetype
            </button>
          </div>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <div className="divide-y divide-gray-100">
            {getArchetypeDetailRowComponent("Core characteristics", arch.characteristic)}
            {getArchetypeDetailRowComponent("100 bonus points divided among the skills", arch.skills)}
            {getArchetypeDetailRowComponent("Suggested occupations", arch.occupations)}
            {getArchetypeDetailRowComponent("Talents", arch.talents, "Any two")}
            {getArchetypeDetailRowComponent("Suggested traits", arch.traits)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div aria-label="archetype-list-container" className="flex">
      <div className="w-2/12 shadow-lg">
        <ul className="divide-y divide-neutral-100">
          {ARCHETYPES.map((archetype, idx) => (
            <>
              {archetype.name === selectedArchetype.name ? (
                <li key={"arch-" + idx} className="flex justify-between gap-x-6 py-5 pl-10 bg-primary-800">
                  <div className="min-w-0 flex-auto">
                    <p className="font-semibold leading-6 text-white">{archetype.name}</p>
                  </div>
                </li>
              ) : (
                <li key={"arch-" + idx} className="flex justify-between gap-x-6 py-5 pl-10 hover:bg-neutral-300 cursor-pointer"
                  onClick={() => setSelectedArchetype(archetype)}>
                  <div className="min-w-0 flex-auto">
                    <p className="font-semibold leading-6">{archetype.name}</p>
                  </div>
                </li>
              )}
            </>
          ))}
        </ul>
      </div>
      <div className='w-10/12'>
        {getSelectedArchetypeComponent(selectedArchetype)}
      </div>
    </div>
  );
}
