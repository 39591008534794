import { useAuth0 } from "@auth0/auth0-react";

export default function LandingPage() {
    const { loginWithRedirect } = useAuth0();

    return (
        <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-24 sm:mt-32 md:mt-56">
            <div className="mx-auto max-w-2xl lg:max-w-none">
                <h1 className="font-display text-title-2xl font-medium tracking-tight text-neutral-950 [text-wrap:balance] sm:text-7xl">
                    A companion for Investigators and Keepers
                </h1>
                <p className="mt-6 text-title-sm text-neutral-600">
                    Get access to tools that we use, so we can all focus on story and not the technology.
                </p>
            </div>
            <div>
                <button onClick={() => loginWithRedirect()}
                    className="inline-block rounded-md bg-primary-600 mt-10 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                    Get started for free
                </button>
            </div>
        </div>
    )

}