import ErrorPage from 'ErrorPage';
import Home from 'Home';
import Builder from "pages/builder/Builder";
import Archetype from "pages/builder/Archetype";
import Characteristics from "./pages/builder/Characteristics";
import Talent from "./pages/builder/Talent";
import Occupation from "./pages/builder/Occupation";
import ArchetypeSkills from "./pages/builder/ArchetypeSkills";
import OccupationSkills from "./pages/builder/OccupationSkills";
import Investigators from "./pages/investigators/Investigators";
import React from "react";
import InvestigatorView from "./pages/investigators/view/InvestigatorView";
import InvestigatorsContainer from "./pages/investigators/InvestigatorsContainer";
import LandingPage from './pages/LandingPage';

export function createRouter(token: Promise<string>) {
    return [
        {
            path: "/",
            element: <Home />,
            errorElement: <ErrorPage />,
            children: [
                { index: true, element: <LandingPage /> },
                {
                    path: "investigators",
                    element: <InvestigatorsContainer />,
                    children: [
                        { index: true, element: <Investigators /> },
                        {
                            path: ":id",
                            element: <InvestigatorView />,
                        },
                        {
                            path: ":id/builder",
                            element: <Builder />,
                            children: [
                                { index: true, element: <Archetype /> },
                                {
                                    path: "archetype",
                                    element: <Archetype />,
                                },
                                {
                                    path: "characteristics",
                                    element: <Characteristics />,
                                },
                                {
                                    path: "talents",
                                    element: <Talent />,
                                },
                                {
                                    path: "occupation",
                                    element: <Occupation />,
                                },
                                {
                                    path: "arch-skills",
                                    element: <ArchetypeSkills />,
                                },
                                {
                                    path: "occ-skills",
                                    element: <OccupationSkills />,
                                }
                            ]
                        },
                    ]
                },
            ],
        },
    ]
}