'use client'
import { NavLink } from './NavLink'
import { Container } from './Container'
import { AppState, LogoutOptions, RedirectLoginOptions, useAuth0, User } from "@auth0/auth0-react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';

const headerStyle = {
  borderBottom: "1px solid #e8b777"
}

function getAuthButtons(user: User | undefined,
  loginWithRedirect: { (options?: RedirectLoginOptions<AppState>): Promise<void>; (): void; },
  logout: { (options?: LogoutOptions): Promise<void>; (arg0: { logoutParams: { returnTo: string; }; }): void; },
  getAccessTokenSilently: () => Promise<any>
) {
  if (user) {
    return (
      <div className="inline-block rounded-lg px-2 py-1 text-base text-neutral-100">
        <Menu as="div" className="relative -ml-px block">
          <MenuButton>
            <span className="sr-only">Open options</span>
            My account
          </MenuButton>
          <MenuItems
            className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            <div className="py-1">
              <MenuItem>
                {({ focus }) => (
                  <button onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                    className='text-gray-700 block mx-auto py-2 text-base'
                  >
                    Sign out
                  </button>

                )}
              </MenuItem>
              {(!process.env.NODE_ENV || process.env.NODE_ENV === "development") ? (
                <MenuItem>
                  <button onClick={async () => navigator.clipboard.writeText(`Bearer ${await getAccessTokenSilently()}`)} className='text-gray-700 block mx-auto py-2 text-base'>
                    Copy JWT
                  </button>
                </MenuItem>
              ) : null}
            </div>
          </MenuItems>
        </Menu>
      </div>
    );
  }

  return (
    <>
      <div className="hidden md:block">
        <button onClick={() => loginWithRedirect()}
          className="inline-block rounded-lg px-2 py-1 text-sm text-neutral-100">
          Sign in
        </button>
      </div>
      <button onClick={() => loginWithRedirect()}
        className="inline-block rounded-lg px-2 py-1 text-sm text-neutral-100">
        <span>
          Get started <span className="hidden lg:inline">today</span>
        </span>
      </button>
    </>
  )
}

export default function Header() {
  const { user, loginWithRedirect, logout, getAccessTokenSilently } = useAuth0();
  return (
    <header className="py-8 bg-primary-900" style={headerStyle}>
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <NavLink href="/">
              Necronomicon
            </NavLink>
            {user ? (
              <div className="hidden md:flex md:gap-x-6">
                <NavLink href="/investigators">Investigators</NavLink>
              </div>
            ) : <></>}
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {getAuthButtons(user, loginWithRedirect, logout, getAccessTokenSilently)}
          </div>
        </nav>
      </Container>
    </header>
  )
}
