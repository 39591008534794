import { useReducer } from "react";
import { InvestigatorContext, InvestigatorDispatchContext, investigatorReducer } from "./InvestigatorContext";
import { Investigator } from "model/Investigator";
import { Outlet } from "react-router-dom";

export default function InvestigatorsContainer() {
    const [investigator, dispatch] = useReducer(
        investigatorReducer,
        new Investigator()
    );

    return (
        <InvestigatorContext.Provider value={investigator}>
            <InvestigatorDispatchContext.Provider value={dispatch}>
                <Outlet />
            </InvestigatorDispatchContext.Provider>
        </InvestigatorContext.Provider>
    );
}