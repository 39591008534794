import { Investigator } from "model/Investigator"
import { useNavigate } from "react-router-dom";
import { InvestigatorDispatchAction, InvestigatorDispatchContext } from "./InvestigatorContext";
import { useContext } from "react";

interface InvestigatorCardProps {
    investigator: Investigator;
    onDelete: (id: String) => void;
}

export default function InvestigatorCard({ investigator, onDelete }: InvestigatorCardProps) {
    let navigate = useNavigate()
    const investigatorDispatch = useContext(InvestigatorDispatchContext)

    function onView(investigator: Investigator) {
        investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: investigator})
        navigate(investigator.id as string, { relative: "path" })
    }

    function onEdit(investigator: Investigator) {
        investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: investigator})
        navigate(investigator.id+'/builder', { relative: "path" })
    }

    return (
        <>
            <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                        <h3 className="truncate text-sm font-medium text-gray-900">{investigator.info.name}</h3>
                        {/* <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            {person.role}
                        </span> */}
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500">{investigator.info.archetype}</p>
                </div>
                {/* <img alt="" src={person.imageUrl} className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300" /> */}
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                        <button
                            onClick={() => onView(investigator)}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                        >
                            VIEW
                        </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                        <button
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            onClick={() => onEdit(investigator)}
                        >
                            EDIT
                        </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                        <button
                            className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-red-600"
                            onClick={() => onDelete(investigator.id)}
                        >
                            DELETE
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}