import { Investigator } from "model/Investigator"
import { readInvestigator } from "./investigators-api"
import { DispatchActionType, InvestigatorDispatchAction } from "pages/investigators/InvestigatorContext"

export function loadInvestigatorIfRequired(id: string | undefined, investigator: Investigator, investigatorDispatch: React.Dispatch<DispatchActionType> | null, token: Promise<String>) {
    if (id !== investigator.id) {
        const fetchData = async () => {
          const data = await readInvestigator(token, id as String);
          investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: data})
        };
        fetchData();
      }
}