'use client'
import { useContext, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { OccupationDataSkillType, OCCUPATIONS } from './occupation-data'
import { calculateSkillsPoints } from 'model/skill-util'
import { updateInvestigator } from 'api/investigators-api';
import { useAuth0 } from '@auth0/auth0-react';

export default function Occupation() {
  let navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)

  function getInitialState() {
    if (investigator?.info?.occupation === null || investigator.info.occupation === "") {
      return OCCUPATIONS[0]
    } else {
      return OCCUPATIONS.find(it => it.occupation === investigator.info.occupation)!!
    }
  }
  const [selectedOccupation, setSelectedOccupation] = useState(getInitialState())


  function getSelectedOccupationComponent() {
    return (
      <div className="mx-20">
        <div className="my-5">
          <h3 className="text-2xl font-semibold leading-7 text-gray-900">{selectedOccupation.occupation}</h3>
          <p className="mt-1 max-w-3xl text-sm leading-6">Skill points: {calculateSkillsPoints(investigator.characteristics, selectedOccupation.occupation_skill_points)} <span className="font-light text-gray-500">- {selectedOccupation.occupation_skill_points}</span></p>
          <p className="mt-1 max-w-3xl text-sm leading-6">Credit rating: <span className="font-light text-gray-500">{selectedOccupation.credit_rating}</span></p>
        </div>
        <div className="mt-6 border-t border-gray-100">
          <div className="divide-y divide-gray-100">
          </div>
        </div>
      </div>
    )
  }

  function lockCharacteristics() {
    let updatedItem = { ...investigator }
    updatedItem.info.occupation = selectedOccupation.occupation
    updatedItem.initStep = 4
    updateInvestigator(getAccessTokenSilently(), updatedItem)
    investigatorDispatch && investigatorDispatch({ type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: investigator })
    navigate("../arch-skills", { relative: "path" })
  }

  function getOccupationDetailRowComponent(title: string, list: OccupationDataSkillType[], defaultText = "") {
    return (
      <div className="flex w-full px-4 py-6">
        <div className="flex w-1/4 text-sm font-medium text-gray-900">{title}</div>
        <div className="flex w-3/4 ml-2 flex-wrap text-sm text-gray-700">
          {list.length > 0 ? (list.map((item, idx) => (
            <span className="flex" key={idx}>
              {typeof item === "string" ? item : (item.label)}
              {(list.length > 1 && idx !== list.length - 1) ? (<p>,&nbsp;</p>) : (<></>)}
            </span>
          ))) : (<p>{defaultText}</p>)
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div aria-label="occupation-list-container" className="flex">
        <div className="flex-none w-[256px] shadow-lg">
          <ul className="divide-y divide-gray-100 max-h-[700px] overflow-y-auto font-semibold leading-6">
            {OCCUPATIONS.map((occupation, idx) => (
              <div key={"arch-" + idx}>
                {occupation.occupation === selectedOccupation.occupation ? (
                  <li className="flex py-5 bg-slate-700 justify-center">
                    <p className="text-white">{occupation.occupation}</p>
                  </li>
                ) : (
                  <li className="flex py-5 hover:bg-slate-500 cursor-pointer justify-center"
                    onClick={() => setSelectedOccupation(occupation)}>
                    <p>{occupation.occupation}</p>
                  </li>
                )}
              </div>
            ))}
          </ul>
        </div>
        <div className='grow'>
          <div className="flex">
            <div className="flex-grow"></div>
            <button type="button"
              className="relative top-5 right-5 rounded-md bg-slate-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
              onClick={() => lockCharacteristics()}
            >
              Select this occupation
            </button>
          </div>
          {getSelectedOccupationComponent()}
          <div className='w-100 px-16'>
            {getOccupationDetailRowComponent("Suggested contacts", selectedOccupation.suggested_contacts)}
            {getOccupationDetailRowComponent("Skills", selectedOccupation.skills)}
          </div>
        </div>
      </div>
    </>
  );
}

