'use client'
import { useState, useContext } from 'react'
import { useNavigate } from "react-router-dom";
import { InvestigatorContext, InvestigatorDispatchAction, InvestigatorDispatchContext } from '../investigators/InvestigatorContext'
import { TALENTS_SECTIONS } from './talents-sections'
import { Checkbox } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/16/solid'
import { updateInvestigator } from 'api/investigators-api';
import { useAuth0 } from '@auth0/auth0-react';

export default function Talent() {
  const MAX_SELECTED_TALENTS = 2
  let navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  const [enabledArray, setEnabledArray] = useState(
    TALENTS_SECTIONS.flatMap(section => section.talents.flatMap(talent => investigator.info.talents.indexOf(talent.name) > -1))
  )
  const [checkboxDisabled, setCheckboxDisabled] = useState(isMaxSelected(enabledArray))

  function lockTalents() {
    const newTalents: string[] = []
    enabledArray.forEach((value, idx) => {
      if(value) {newTalents.push(TALENTS_SECTIONS[Math.floor(idx/10)].talents[idx%10].name)}
    })
    let updatedItem = {...investigator}
    updatedItem.info.talents = newTalents
    updatedItem.initStep = 3
    updateInvestigator(getAccessTokenSilently(), updatedItem)
    investigatorDispatch && investigatorDispatch({type: InvestigatorDispatchAction.SET_INVESTIGATOR, value: updatedItem})
    navigate("../occupation", { relative: "path" })
  }

  function checkboxChange(value: boolean, selectedValue: string, sectionIdx: number, idx: number): void {
    const newValues: boolean[] = [...enabledArray]
    newValues[sectionIdx*10+idx] = value
    setEnabledArray(newValues)
    setCheckboxDisabled(isMaxSelected(newValues))
  }

  function isMaxSelected(array: boolean[]): boolean {
    let count = 0
    array.forEach(b => {if(b) {count++}})
    return count >= MAX_SELECTED_TALENTS
  }

  function getCheckboxComponent(talent: { name: string, description: string }, sectionIdx: number, idx: number) {
    return (
      <div className="relative flex items-start" key={"talent-" + sectionIdx + "-" + idx}>
        <div className="flex h-6 items-center">
          <Checkbox
            disabled={!enabledArray[sectionIdx*10+idx] && checkboxDisabled}
            checked={enabledArray[sectionIdx*10+idx]}
            onChange={(value) => checkboxChange(value, talent.name, sectionIdx, idx)}
            id={talent.name}
            aria-describedby={talent.name}
            name={talent.name}
            className="group size-6 rounded-md bg-white/10 p-1 ring-1 ring-slate-300 ring-inset data-[checked]:bg-white data-[disabled]:bg-slate-100 data-[disabled]:ring-slate-100"
          >
            <CheckIcon className="hidden size-4 fill-black group-data-[checked]:block" />
          </Checkbox>
        </div>
        <div className="ml-3 text-sm text-left leading-6">
          <label htmlFor={talent.name} className="font-medium text-gray-900">
            {talent.name}
          </label>{' '}
          <span id={talent.name} className="text-gray-500">
            <span className="sr-only">{talent.name}</span>
            {talent.description}
          </span>
        </div>
      </div>
    )
  }

  function getSectionComponent(section: { category: string, talents: { name: string, description: string }[] }, idx: number) {
    return (
      <div className='mb-5' key={"section-" + idx}>
        <div className="border-b border-gray-200 pb-5">
          <h3 className="text-base font-semibold leading-1 text-gray-900">{section.category}</h3>
        </div>
        <div className="space-y-2 py-2">
          {section.talents.map((talent, talentIdx) => (
            getCheckboxComponent(talent, idx, talentIdx)
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="mx-20 my-10">
      <div className="sm:flex sm:items-center sm:justify-between">
        <h1 className="text-2xl font-semibold leading-1 text-gray-900">Chose {MAX_SELECTED_TALENTS} talents</h1>
        <div className="mt-3 flex sm:ml-4 sm:mt-0">
          <button
            type="button"
            className="ml-3 inline-flex items-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600"
            onClick={() => lockTalents()}
          >
            Select these talents
          </button>
        </div>
      </div>
      <fieldset>
        <legend className="sr-only">Investigators Talets</legend>
        {TALENTS_SECTIONS.map((section, idx) => (
          getSectionComponent(section, idx)
        ))}
      </fieldset>
    </div>
  );
}