import InvestigatorInfoComponent from "./InvestigatorInfoComponent";
import CharacteristicsComponent from "./CharacteristicsComponent";
import StatsComponent from "./StatsComponent";
import SkillsComponent from "./SkillsComponent";
import { InvestigatorContext, InvestigatorDispatchContext } from "../InvestigatorContext";
import { useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { loadInvestigatorIfRequired } from "api/investigator-service";
import { compactSkills } from "model/skill-util";

export default function InvestigatorView() {
  const investigator = useContext(InvestigatorContext)
  const investigatorDispatch = useContext(InvestigatorDispatchContext)
  let { id } = useParams();
  const { getAccessTokenSilently } = useAuth0()

  useEffect(() => {
    loadInvestigatorIfRequired(id, investigator, investigatorDispatch, getAccessTokenSilently())
  });

  return (
    <div className="max-w-4xl mx-auto p-6 bg-neutral-200">
      {investigator ? (
        <>
          <h1 className="text-3xl font-bold text-center mb-6">{investigator.info.name}</h1>
          <div className="space-y-6">
            <InvestigatorInfoComponent info={investigator.info} />
            <CharacteristicsComponent characteristics={investigator.characteristics} />
            <StatsComponent stats={investigator.stats} />
            <SkillsComponent skills={compactSkills(investigator.skills)} />
          </div>
        </>
      ) : (<></>)}
    </div>
  );
}