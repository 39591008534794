import { Link } from "react-router-dom"

export function NavLink({
  href,
  children,
}: {
  href: string
  children: React.ReactNode
}) {
  return (
    <Link
      to={href}
      className="inline-block px-2 py-1 text-base text-neutral-100"
    >
      {children}
    </Link>
  )
}
